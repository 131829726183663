import React, { Component } from 'react'
import VideoJSPlayer from './videoJSPlayer'
import { FORMAT_VIDEO_CAN_USE_MP4 } from 'config/constants/common'
class VideoPlayer extends Component {
  render() {
    const { src = '', video = {}, options = {}, ...rest } = this.props

    const { url = '' } = video || {}
    const newArr = url.split('.')
    const videoType = newArr[newArr.length - 1].replace(' ', '').toLowerCase()

    const sourceVideo = {
      src: src || url,
      type: `video/${FORMAT_VIDEO_CAN_USE_MP4.includes(videoType) ? 'mp4' : videoType}`,
    }

    const videoJsOptions = {
      autoplay: true,
      controls: true,
      sources: sourceVideo,
      ...options,
    }

    return <VideoJSPlayer {...videoJsOptions} {...rest} />
  }
}
export default VideoPlayer
