import React from 'react'
import videojs from 'video.js'
import { isNil } from 'lodash'
import { pageEvent } from 'utils/tracker'
import 'video.js/dist/video-js.css'
import './video.scss'

const PERCENT_RATIO = 56.25 / 100

class VideoJSPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ended: true,
    }
  }

  componentDidMount() {
    const { onTrackingSource = () => {}, currentTime } = this.props

    if (typeof onTrackingSource === 'function') {
      // add tracking source
      onTrackingSource()
    }
    // instantiate Video.js
    this.player = videojs(this.videoNode, this.props)
    // update current time video
    if (currentTime && typeof currentTime === 'number') {
      this.videoNode.currentTime = currentTime
    }
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions)
  }

  componentDidUpdate(prevProps) {
    const { currentTime: prevCurrentTime } = prevProps
    const { currentTime } = this.props

    if (currentTime !== prevCurrentTime && !isNil(currentTime) && typeof currentTime === 'number') {
      this.videoNode.currentTime = currentTime
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    let { innerWidth, innerHeight } = window
    let styleVideoContainer = {}

    if (innerWidth >= 1280) {
      innerWidth = (innerWidth * 70) / 100
    } else if (innerWidth < 1280 && innerWidth >= 1200) {
      innerWidth = (innerWidth * 75) / 100
    } else if (innerWidth < 1200 && innerWidth >= 1120) {
      innerWidth = (innerWidth * 80) / 100
    } else if (innerWidth < 1120 && innerWidth >= 1040) {
      innerWidth = (innerWidth * 85) / 100
    } else if (innerWidth < 1040 && innerWidth >= 960) {
      innerWidth = (innerWidth * 90) / 100
    } else {
      innerWidth -= innerHeight > innerWidth * PERCENT_RATIO + 40 ? 0 : 40
      innerHeight -= innerWidth > innerHeight / PERCENT_RATIO + 40 ? 0 : 40
    }
    const heightRatio = innerWidth * PERCENT_RATIO

    if (heightRatio < innerHeight) {
      styleVideoContainer = { width: innerWidth, height: heightRatio }
    } else if (heightRatio >= innerHeight) {
      styleVideoContainer = { width: innerHeight / PERCENT_RATIO, height: innerHeight }
    }
    this.setState({ styleVideoContainer })
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    const { hiddenPip, trackingEventSource } = this.props
    const { ended, styleVideoContainer } = this.state

    return (
      <div className={`video-container ${hiddenPip ? 'not-pip' : ''}`} style={styleVideoContainer}>
        <div data-vjs-player>
          <video
            id="videoPlayer"
            ref={(node) => (this.videoNode = node)}
            className="video-js"
            disablePictureInPicture
            onPlay={() => {
              if (trackingEventSource && ended) {
                pageEvent(trackingEventSource, 'video')
                this.setState({ ended: false })
              }
            }}
            onEnded={() => this.setState({ ended: true })}
          />
        </div>
      </div>
    )
  }
}

export default VideoJSPlayer
